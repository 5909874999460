import React from 'react'

import './aboutme.css'

const Aboutme = () => {
  return (
    <div className='container'>
        <div className='aboutme'>
            <div className='aboutme-heading'>
                <p className='aboutme-heading-txt'>About me</p>
            </div>

            <div>
                <p className='aboutme-txt'>
                Hello, I'm Shivakumar, a passionate and versatile full-stack developer with expertise in HTML, CSS, Python, and Java. <br/><br/>I thrive on turning innovative ideas into functional and user-friendly web applications, and I specialize in utilizing the Django framework to bring concepts to life. With a strong foundation in front-end technologies like HTML and CSS, coupled with back-end prowess in Python and Java, I am dedicated to crafting seamless and responsive web experiences.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Aboutme