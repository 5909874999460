import React from 'react';
import './navbar.css';

const Navbar = () => {
  return (
   <div>
        <div className='navbar'> 
            <div className='navbar_links'>
                <div className='logo'>
                    <p className='logo_name'>Shivakumar</p>
                </div>
                <div>
                    <a href='mailto:shivubmdev@gmail.com'><button class="btn2">
  <span>
    Contact
  </span>
</button></a>
                </div>
            </div>
        </div>
   </div>
  )
}

export default Navbar