import React from 'react';

import { Navbar, Footer } from './components';
import { Header, Projects, Aboutme} from './containers';

import './/App.css';

const App = () => {
  return (
    <div className='App'>
      <Navbar/>
      <Header/>
      <Aboutme/>
      <Projects/>
      <Footer/>
    </div>
  )
}

export default App