import React from 'react'

import './project.css'

import logo from '../../assets/expenser-bg.png';
import logo1 from '../../assets/medicuality-bg.png'

const Projects = () => {
  return (
    <div>
    <div className='project-heading'>
            <p className='project-heading-txt'>Projects</p>
    </div>
    <div className='container-project'>
        
        <div class="card">
            <div class="card-image">
                <img src={logo} alt='logo'></img>
            </div>
            <p class="card-title">Expenser</p>
            <p class="card-body">
                A Money Mangement and Tracking Application To Manage Expenses
            </p>
            <p class="footer">Developed By <span class="by-name">Shivakumar</span></p>
        </div>
        <div class="card">
            <div class="card-image">
                <img src={logo1} alt='logo'></img>
            </div>
            <p class="card-title">Billing System</p>
            <p class="card-body">
                A Billing System Build for Pharmacy
            </p>
            <p class="footer">Developed By <span class="by-name">Shivakumar</span></p>
        </div>
    </div>
    </div>
  )
}

export default Projects