import React from 'react'
import './header.css'
import 'animate.css'

const Header = () => {
  return (
    <div className='header'>
        <div className='header-txt'>
            <p className='header-txt1'>Shivakumar</p>
        </div>
        <div className='header-txt-sub'>
            <p className='header-txt-sub1'>Full Stack Developer</p></div>
    </div>
  )
}

export default Header