import React from 'react'

import './footer.css'

const Footer = () => {
  return (
    <div>
        <div className='footer-container'>
            <div className='footer-txt'>
                <p>Developed By: <b>Shivakumar</b></p>
            </div>
            <div className='footer-txt'>
                <p>Email: <b>shivubmdev@gmail.com</b></p>
            </div>
            <div className='version-head'>
                <p className='version'>Version 1.0</p>
            </div>
        </div>
    </div>
  )
}

export default Footer